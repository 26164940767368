import React, { useCallback, useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import dayjs from "dayjs";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styles from "./styles.module.scss";
import { Button, Typography, Row } from "antd";
import axios from "utils/axios";

import "moment/locale/ru";

moment.locale("ru");
import SheduleModal from "./modals/scheduleModal";
import ConditionalRender from "components/ConditionalRender";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const messages = {
	next: "Далее",
	previous: "Назад",
	today: "Сегодня",
	month: "Месяц",
	week: "Неделя",
	day: "День",
};

const { Title } = Typography;

const Schedule = () => {
	const [events, setEvents] = useState([]);
	const [event, setEvent] = useState(null);
	const [user, setUser] = useState(null);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const views = {
		month: true,
		week: true,
		day: true,
	};

	const [openSchedule, setOpenSchedule] = useState(false);

	const getUsers = useCallback(async () => {
		const res = await axios.get("/user/staff");

		if (res.data) {
			const array = res.data.map(({ id, name, color }) => ({
				id,
				label: name,
				color,
			}));
			setUsers(array);
			setUser(array[0]);
		}
	}, []);

	useEffect(() => {
		if (!users.length) {
			getUsers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlechangeUser = (id) => {
		const findUser = users.find((user) => user.id === id);
		if (findUser) {
			setUser(findUser);
		}
	};

	const handleSelectSlot = useCallback(
		({ start, end }) => {
			const title = window.prompt("New Event Name");
			if (title) {
				setEvents((prev) => [...prev, { start, end, title }]);
			}
		},
		[setEvents]
	);

	const handleSelectEvent = useCallback(
		(event) => {
			const { userId } = event;

			if (userId) {
				const findUser = users.find((user) => user.id === userId);

				if (findUser) {
					setUser(findUser);
					setOpenSchedule(true);
				}
			}

			setEvent(event);
		},
		[users]
	);

	const fetchedShedules = useCallback(async () => {
		const res = await axios.get("/schedule");
		const shedules =
			res?.data &&
			res.data.map(({ id, userId, user, dateStart, dateEnd }) => ({
				id,
				userId,
				title: user.name,
				start: new Date(dateStart),
				end: new Date(dateEnd),
				color: user.color,
			}));

		setEvents(shedules);
	}, []);

	useEffect(() => {
		fetchedShedules();
	}, []);

	const eventPropGetter = (event, start, end, isSelected) => {
		const style = {
			backgroundColor: event.color,
			borderRadius: "5px",
			color: "white",
			border: "0px",
			opacity: 0.8,
			display: "block",
		};

		return {
			style,
		};
	};

	const handleUpdateUserColor = React.useCallback(
		async (color, userId) => {
			try {
				setLoading(true);

				const res = await axios.put(`/user/color/${userId}`, {
					color,
				});
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		},
		[user]
	);

	const handleCloseModalSchedule = () => {
		setOpenSchedule(false);
		setEvent(null);
		setUser(null);
	};

	const handleCloseModalAndFectch = () => {
		setOpenSchedule(false);
		setEvent(null);
		setUser(null);
		fetchedShedules();
		getUsers();
	};

	return (
		<div className={styles.formContainer}>
			<Row>
				<Title level={2}>График работы</Title>
			</Row>

			<div className={styles.toolbar}>
				<ConditionalRender roles={[1]}>
					<Button
						type="primary"
						size="large"
						style={{
							marginBottom: 30,
						}}
						onClick={() => setOpenSchedule(true)}
					>
						Новое
					</Button>
				</ConditionalRender>
			</div>

			<div style={{ height: "60vh", width: "90%" }}>
				<DnDCalendar
					views={views}
					defaultDate={moment().toDate()}
					defaultView="week"
					events={events}
					localizer={localizer}
					messages={messages}
					onSelectEvent={(event) => handleSelectEvent(event)}
					onSelectSlot={handleSelectSlot}
					// onEventDrop={onEventDrop}
					// onEventResize={onEventResize}
					resizable={false}
					eventPropGetter={eventPropGetter}
					style={{ height: "60vh" }}
				/>
			</div>
			<SheduleModal
				isOpen={openSchedule}
				user={user}
				users={users}
				event={event}
				handleUpdateUserColor={handleUpdateUserColor}
				onClose={() => handleCloseModalSchedule()}
				onCloseModalAndFectch={() => handleCloseModalAndFectch()}
				onChangeUser={(id) => handlechangeUser(id)}
			/>
		</div>
	);
};

export default Schedule;
