import { useCallback, useState, useEffect } from "react";

import axios from "utils/axios";

import { Button, Input } from "shared/components";

import { useFormik } from "formik";
import SelectMultiRole from "../../components/SelectMultiRole";

import * as yup from "yup";
import { Form } from "antd";
import Notification from "shared/components/Notification";

interface FormValues {
	name: string;
	roles: [];
}

const CreateDashboard = () => {
	const [loading, setLoading] = useState(false);

	const fetchWidgets = useCallback(async () => {
		const response = await axios.get(`/widget/types`);
	}, []);

	useEffect(() => {
		fetchWidgets();
	}, []);

	const handleSave = async (values: FormValues) => {
		setLoading(true);
		try {
			await axios.post(`/widget/dashboard`, values);
			Notification("success", "Дашборд успешно создан");
			resetForm();
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	const { values, setFieldValue, handleSubmit, resetForm } =
		useFormik<FormValues>({
			onSubmit: (values) => handleSave(values),
			initialValues: {
				name: "",
				roles: [],
			},
			validationSchema: yup.object().shape({
				name: yup.string().required("Это поле обязательно"),
				roles: yup
					.array()
					.min(1, "Хотя бы одна роль")
					.required("Это поле обязательно"),
			}),
			enableReinitialize: true,
		});

	const handleChangeSelect = (name: string) => (value: number) => {
		setFieldValue(name, value);
	};

	const handleChange =
		(name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setFieldValue(name, event.target.value);
		};

	return (
		<Form style={{ width: "100%" }} layout="vertical">
			<Form.Item>
				<Input
					placeholder="Наименование"
					value={values.name}
					onChange={handleChange("name")}
				/>
			</Form.Item>

			<Form.Item label="" name={"roles"}>
				<SelectMultiRole
					values={values.roles}
					onChange={handleChangeSelect("roles")}
				/>
			</Form.Item>

			<Form.Item>
				<Button loading={loading} onClick={() => handleSubmit()}>
					Создать
				</Button>
			</Form.Item>
		</Form>
	);
};

export default CreateDashboard;
