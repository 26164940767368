import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import axios from "utils/axios";
import Loading from "shared/components/Loading";
import BarChart from "../BarChart";

import { useDashboard } from "pages/Dashboard";

import { dateToApiDateFormat } from "utils/helpers";

type WidgetTicketsProps = {
	date?: string[];
};

const WidgetKioskProblem: React.FC<WidgetTicketsProps> = () => {
	const { filter, restart } = useDashboard();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [total, setTotal] = useState(0);

	const getTotalValue = (data) => {
		return data.reduce((total, item) => {
			return total + item.bars.reduce((sum, bar) => sum + bar.value, 0);
		}, 0);
	};

	const fetchData = async () => {
		setLoading(true);

		const response = await axios
			.get(`/widget/problems`, {
				params: {
					ticketDateStart: dateToApiDateFormat(filter.dateStart),
					ticketDateEnd: dateToApiDateFormat(filter.dateEnd),
				},
			})
			.finally(() => setLoading(false));

		const dataRes = response?.data || [];

		setTotal(getTotalValue(dataRes));
		setData(dataRes);

		return response;
	};

	useEffect(() => {
		fetchData();
	}, [filter, restart]);

	return (
		<Loading loading={loading} className={styles.widgetLoading}>
			<div className={styles.container}>
				<div className={styles.header}>
					<div className={styles.title}>Проблемные киоски</div>
				</div>
				<div className={styles.count}>{total}</div>
				<div className={styles.bar} style={{ height: "205px" }}>
					<BarChart data={data} maxValue={3} />
				</div>
			</div>
		</Loading>
	);
};

export default WidgetKioskProblem;
