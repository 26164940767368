import React, { useState, createContext, useContext } from "react";

import { useProfile } from "hooks/useContext";

import styles from "./styles.module.scss";

import { ExcludeSvg } from "shared/icons";
import WidgetGrid from "shared/components/WidgetGrid";

import { useSidebar } from "shared/components/Layout";
import Message from "shared/components/Notification";
import useStateFromQuery from "hooks/useStateFromQuery";
import SelectRole from "./components/SelectRole";
import SelectDashboardLayout from "./components/SelectDashboardLayout";
import { TimeRangePicker, DatePicker, Button, Switch } from "shared/components";
import { useWidgetGrid } from "./hooks/useWidgetGrid";
import DashboardSettings from "./components/DashboardSettings";
import { getDateFromApiDateFormat } from "utils/helpers";
import moment from "moment-timezone";
import dayjs from "dayjs";
import { dateToApiDateFormat } from "utils/helpers";

import "dayjs/locale/ru";
dayjs.locale("ru");
type UserType = {
	name: string;
	roles: { name: string }[];
} | null;

const options = [
	{
		value: 1,
		label: "Сегодня",
	},
	{
		value: 2,
		label: "Вчера",
	},
	{ value: 3, label: "Неделя" },
	{ value: 4, label: "Месяц" },
	{ value: 5, label: "Квартал" },
];

const today = dateToApiDateFormat(moment().format("yyyy-MM-DD"));

const FILTER_INITIAL = {
	dateStart: today,
	dateEnd: today,
};

const DashboardContext = createContext<{
	restart: boolean;
	addWidget: (id: number) => void;
	schemeWidgets: number | null;
	setIsEditMode: (state: boolean) => void;
	isEditMode: boolean;
	setPriorityDashboard: (id: number) => void;
	filter: any;
	// toggleSidebar
}>(undefined);

const Dashboard: React.FC = () => {
	const [restart, setRestart] = useState(0);
	const { isSidebarVisible, toggleSidebar } = useSidebar();
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const { user, isAdmin } = useProfile();
	const [filter, setFilter] = useStateFromQuery(FILTER_INITIAL, [
		{
			key: "dateStart",
			cb: getDateFromApiDateFormat,
		},
		{
			key: "dateEnd",
			cb: getDateFromApiDateFormat,
		},
	]);

	const User: { user: UserType } = useProfile();
	const firstName = React.useMemo(
		() => (User?.user?.name || "").split(" ")[0],
		[user]
	);

	const {
		fetchSchemesForRole,
		schemeWidgets,
		isLoadingWidgets,
		schemes,
		widgets,
		setSchemeWidgets,
		addWidget,
		changeWidgetPositionInDashboard,
		removeWidgetFromDashboard,
		setPriorityDashboard,
	} = useWidgetGrid();

	const [loading, setLoading] = React.useState(false);

	return (
		<DashboardContext.Provider
			value={{
				restart,
				addWidget,
				schemeWidgets,
				isEditMode,
				setIsEditMode,
				changeWidgetPositionInDashboard,
				removeWidgetFromDashboard,
				setPriorityDashboard,
				filter,
			}}
		>
			<header className={styles.header}>
				<div className={styles.block}>
					<div className={styles.gretting}>
						<div className={styles.hi}>{`Привет, `}</div>
						<div className={styles.name}>{firstName}</div>
					</div>
				</div>

				<div className={styles.control}>
					{isAdmin && (
						<Switch
							onChange={(state) => setIsEditMode(state)}
							style={{ marginRight: "10px" }}
						/>
					)}

					<div className={styles.updateInformation}>
						<span>Время обновления данных: </span>
						<span>{dayjs().format("HH:mm, MMM DD, YYYY")}</span>
					</div>

					<Button
						style={{ marginRight: "5px" }}
						loading={loading}
						onClick={() => {
							Message("success", "Дэшборд обновлен успешно");
							setRestart((state) => state + 1);
						}}
					>
						Обновить
					</Button>

					<Button
						className={isSidebarVisible ? styles.activeSidebar : ""}
						icon={<ExcludeSvg />}
						onClick={toggleSidebar}
					/>
				</div>
			</header>
			<div className={styles.toolbar}>
				<div className={styles.toolbarLeft}>
					<div className={styles.block}>
						<SelectRole onChange={fetchSchemesForRole} />
						<SelectDashboardLayout
							options={schemes}
							onChange={setSchemeWidgets}
							defaultValue={schemeWidgets}
							loading={isLoadingWidgets}
						/>
					</div>

					{!isEditMode && (
						<React.Fragment>
							<div className={styles.block} style={{ marginRight: "10px" }}>
								<TimeRangePicker
									options={options}
									defaultValue={options[0].value}
									onChange={(date) => {
										setFilter({
											...filter,
											dateStart: dateToApiDateFormat(
												date.startDate.format("yyyy-MM-DD")
											),
											dateEnd: dateToApiDateFormat(
												date.endDate.format("yyyy-MM-DD")
											),
										});
									}}
								/>
							</div>

							<div className={styles.block}>
								<DatePicker
									values={[filter.dateStart, filter.dateEnd]}
									onChange={(date) => {
										setFilter({
											...filter,
											dateStart: dateToApiDateFormat(date.values[0]),
											dateEnd: dateToApiDateFormat(date.values[1]),
										});
									}}
								/>
							</div>
						</React.Fragment>
					)}
				</div>
				<div className={styles.toolbarRight}>
					<div className={styles.block}>
						{isEditMode && <DashboardSettings />}
					</div>
				</div>
			</div>

			<WidgetGrid
				loading={isLoadingWidgets}
				widgets={widgets}
				restart={restart}
			/>
		</DashboardContext.Provider>
	);
};

export const useDashboard = () => {
	const context = useContext(DashboardContext);

	if (!context) {
		throw new Error("useDashboard must be used within a DashboardProvider");
	}
	return context;
};

export default Dashboard;
