import { Button as AtndButton, ButtonProps as AntButtonProps } from "antd";
import styles from "./styles.module.scss";

// export type ButtonProps = React.ComponentProps<typeof AtndButton>;

export interface ButtonProps extends AntButtonProps {}

export function Button({ className, ...restProps }: ButtonProps) {
	return (
		<AtndButton
			className={[styles.btn, className].join(" ")}
			type={"text"}
			{...restProps}
		/>
	);
}
