import { Spin, SpinProps as AntSpinProps } from "antd";
import styles from "./styles.module.scss";

export interface LoadingProps extends AntSpinProps {
	loading: boolean;
}
const Loading = ({
	children,
	loading,
	className,
	...restProps
}: LoadingProps) => {
	return (
		<Spin
			// rootClassName={styles.spin}
			wrapperClassName={[styles.spin, className].join(" ")}
			spinning={loading}
			indicator={<span className={styles.loader}></span>}
			{...restProps}
		>
			{children}
		</Spin>
	);
};

export default Loading;
