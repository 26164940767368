import React, { useState, useEffect, useMemo } from "react";
import { Select } from "shared/components";
import { StartSvg } from "shared/icons";
import styles from "./styles.module.scss";
import { Scheme } from "../../hooks/useWidgetGrid";
import { useDashboard } from "pages/Dashboard";

const SelectDashboardLayout: React.FC<{
	onChange?: (id: number) => void;
	options: Scheme[];
	loading: boolean;
	defaultValue: number | null;
}> = ({ onChange, options, loading, defaultValue }) => {
	const [value, setValue] = useState(defaultValue);

	const { setPriorityDashboard, isEditMode } = useDashboard();

	const data = useMemo(() => {
		return (
			options?.map(({ id, name, priority }: Scheme) => ({
				value: id,
				id,
				label: (
					<React.Fragment>
						{isEditMode && (
							<span
								onClick={(e) => {
									e.stopPropagation();
									setPriorityDashboard(id);
								}}
								className={[styles.item, priority ? styles.priority : ""].join(
									" "
								)}
							>
								<StartSvg />
							</span>
						)}

						<span>{name}</span>
					</React.Fragment>
				),
			})) || []
		);
	}, [options, isEditMode]);

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	return (
		<Select
			className={styles.selectLayout}
			popupMatchSelectWidth={false}
			options={data}
			value={value}
			loading={loading}
			disabled={loading}
			style={{ width: "154px", marginRight: "10px", height: "40px" }}
			onChange={(value) => {
				onChange && onChange(value);
				setValue(value);
			}}
		/>
	);
};

export default SelectDashboardLayout;
