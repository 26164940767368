import { useCallback, useState, useEffect } from "react";

import axios from "utils/axios";

import { Select } from "shared/components";

import { useFormik } from "formik";

import * as yup from "yup";
import { Form } from "antd";
import Notification from "shared/components/Notification";

const validationSchema = yup.object().shape({
	dashboards: yup.array(),
	role: yup.number(),
});

const EditDashboard = () => {
	const [loading, setLoading] = useState(false);
	const [allDashboards, setAllDashboards] = useState([]);
	const [defaultDashboards, setDefaultDashboards] = useState<number[]>([]);
	const [roles, setRoles] = useState([]);

	const getRoles = useCallback(async () => {
		try {
			setLoading(true);
			const res = await axios.get("/role");
			setRoles(
				res.data.map(({ id, name }: { id: number; name: string }) => ({
					value: id,
					id,
					label: name,
				}))
			);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, []);

	const getAllDashboards = useCallback(async () => {
		try {
			setLoading(true);
			const response = await axios.get("widget/dashboards");
			setAllDashboards(
				response.data.map(({ id, name }: { id: number; name: string }) => ({
					value: id,
					key: id,
					id,
					label: name,
				}))
			);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getAllDashboards();
		getRoles();
	}, []);

	const { values, setFieldValue, setValues } = useFormik({
		onSubmit: () => {},
		initialValues: {
			dashboards: defaultDashboards.length > 0 ? defaultDashboards : [],
			role: undefined,
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
	});

	const [key, setKey] = useState(0);

	const getDashboardsByRole = useCallback(
		async (roleId: number) => {
			try {
				setLoading(true);

				const response = await axios.get(`/widget/${roleId}/dashboards`);

				const data: { dashboardId: number }[] = response?.data || [];

				const ids = data.map((item) => item.dashboardId);

				setDefaultDashboards(ids);

				setLoading(false);
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
				setKey((prev) => prev + 1);
			}
		},

		[allDashboards]
	);

	const fetchAddDashboard = useCallback(
		async (id: number) => {
			if (values.role) {
				try {
					setLoading(true);

					await axios.put(`/widget/role/${values.role}/dashboard/${id}`);

					setLoading(false);
				} catch (e) {
					console.log(e);
				} finally {
					setLoading(false);
				}
			} else {
				Notification("info", "Нужно выбрать роль");
			}
		},
		[values]
	);

	const fetchRemoveDashboard = useCallback(
		async (id: number) => {
			if (values.role) {
				try {
					setLoading(true);

					await axios.delete(`/widget/role/${values.role}/dashboard/${id}`);

					setLoading(false);
				} catch (e) {
					console.log(e);
				} finally {
					setLoading(false);
				}
			} else {
				Notification("info", "Нужно выбрать роль");
			}
		},
		[values]
	);

	const handleChangeSelect = (name: string) => (value: number) => {
		if (name === "role") {
			getDashboardsByRole(value);
		}

		setFieldValue(name, value);
	};

	useEffect(() => {
		setValues((values) => {
			return { ...values, dashboards: defaultDashboards };
		});
	}, [defaultDashboards]);

	return (
		<Form style={{ width: "100%" }} layout="vertical">
			<Form.Item label="" name={"role"}>
				<Select
					popupMatchSelectWidth={false}
					options={roles}
					value={values?.role}
					placeholder={"Роль"}
					onChange={handleChangeSelect("role")}
				/>
			</Form.Item>

			<Form.Item label="" name={"dashboards"}>
				<Select
					key={key}
					options={allDashboards}
					placeholder={"Дэшборды"}
					loading={loading}
					mode={"multiple"}
					value={values.dashboards}
					defaultValue={values.dashboards}
					onChange={handleChangeSelect("dashboards")}
					onDeselect={(id) => fetchRemoveDashboard(id)}
					onSelect={(id) => fetchAddDashboard(id)}
				/>
			</Form.Item>
		</Form>
	);
};

export default EditDashboard;
