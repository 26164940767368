import { useState } from "react";
import { message } from "antd";
import Notification from "shared/components/Notification";

import { useProfile } from "hooks/useContext";
const msg = [
	"Желаю вам продуктивного дня и успешного завершения всех задач!",
	"Пусть ваш рабочий день будет наполнен вдохновением и эффективными решениями!",
	"Удачи сегодня! Пусть каждая минута вашего рабочего дня принесет вам радость и удовлетворение от достижения целей!",
	"Желаю вам ярких идей, легких решений и позитивного настроения на протяжении всего дня!",
];

const useWelcomeMessage = () => {
	const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
	const { user } = useProfile();
	const handlerSetShowWelcomeMessage = () => {
		const userHasSeenWelcomeMessage = localStorage.getItem(
			"userHasSeenWelcomeMessage"
		);
		if (userHasSeenWelcomeMessage) {
			setShowWelcomeMessage(false);
		} else {
			showWelc();
			localStorage.setItem("userHasSeenWelcomeMessage", "true");
		}
	};
	const showWelc = () => {
		setTimeout(() => {
			Notification("success", "Добро пожаловать в ServiceDesk", 5);
			message.success(
				`${user.name}, ${msg[Math.floor(Math.random() * msg.length)]}`,
				4
			);
		}, 1000);
	};

	return { handlerSetShowWelcomeMessage, showWelcomeMessage };
};

export default useWelcomeMessage;
