import React, { useEffect, useState, useCallback } from "react";
import styles from "./styles.module.scss";
import axios from "utils/axios";
import Loading from "shared/components/Loading";
import { Dropdown } from "shared/components/Dropdown";
import { PlusSvg } from "../../../icons";
import { useDashboard } from "pages/Dashboard";

export type WidgetAddWidgetProps = {
	date?: string[];
};

export type WidgetType = {
	id: number;
	name: string;
};

const WidgetAddWidget: React.FC<WidgetAddWidgetProps> = ({ date }) => {
	const { restart, addWidget, schemeWidgets } = useDashboard();
	const [items, setItems] = useState<{ label: string; key: number }[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const renderItem = useCallback(
		(label: string, id: number) => {
			return (
				<div style={{ padding: "10px" }} onClick={() => addWidget(id)}>
					{label}
				</div>
			);
		},
		[schemeWidgets]
	);

	const fetchData = async () => {
		setLoading(true);
		const response = await axios
			.get<WidgetType[]>(`/widget/types`)
			.finally(() => setLoading(false));

		const data = response?.data || null;

		if (data) {
			setItems(
				data.map((item) => ({
					label: item.name,
					key: item.id,
				}))
			);
		}

		return response;
	};

	useEffect(() => {
		fetchData();
	}, [restart]);

	return (
		<Loading loading={loading} className={styles.widgetLoading}>
			<div className={styles.container}>
				<Dropdown
					items={items.map(({ label, key }) => ({
						key,
						id: key,
						label: renderItem(label, key),
					}))}
					trigger={["click"]}
				>
					<div className={styles.content}>
						<div className={styles.header}>Добавить Виджет</div>
						<div className={styles.img}>
							<PlusSvg />
						</div>
					</div>
				</Dropdown>
			</div>
		</Loading>
	);
};

export default WidgetAddWidget;
