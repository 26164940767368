import { Input as AntdInput } from "antd";
import { InputProps as AntdInputProps } from "antd/lib/input";

import styles from "./styles.module.scss";

export interface IntputProps extends AntdInputProps {}

export function Input({ ...props }: IntputProps) {
	return <AntdInput className={styles.input} {...props} />;
}
