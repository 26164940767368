import { useCallback, useState, useEffect, useMemo } from "react";
import { Select } from "shared/components";
import axios from "utils/axios";

const SelectRole = ({
	onChange,
	values,
}: {
	onChange?: (roleId: number) => void;
	values: string[];
}) => {
	const [roles, setRoles] = useState([]);

	const getRoles = useCallback(async () => {
		const res = await axios.get("/role");
		setRoles(res.data);
	}, []);

	useEffect(() => {
		if (!roles.length) {
			getRoles();
		}
	}, [getRoles, roles.length]);

	const optionsRoles = useMemo(() => {
		return roles.map((item) => ({
			id: item.id,
			key: item.id,
			label: item.name,
		}));
	}, [roles]);

	return (
		<Select
			placeholder={"Роли"}
			popupMatchSelectWidth={false}
			options={optionsRoles}
			value={values}
			mode={"multiple"}
			onChange={onChange}
		/>
	);
};

export default SelectRole;
