import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import moment from "moment-timezone";
import { Select } from "shared/components";
interface Option {
	id: number;
	name: string;
	unit: moment.unitOfTime.StartOf | "yesterday";
}

const options: Option[] = [
	{ id: 1, name: "Сегодня", unit: "day" },
	{ id: 2, name: "Вчера", unit: "yesterday" },
	{ id: 3, name: "Неделя", unit: "week" },
	{ id: 4, name: "Месяц", unit: "month" },
	{ id: 5, name: "Квартал", unit: "quarter" },
];

type TimeRangePickerProps = {
	onChange?: ({
		startDate,
		endDate,
	}: {
		startDate: moment.Moment;
		endDate: moment.Moment;
	}) => void;
};

export function TimeRangePicker({ onChange }: TimeRangePickerProps) {
	const [selected, setSelected] = useState<Option>(options[0]);
	const [highlightStyle, setHighlightStyle] = useState<React.CSSProperties>({});
	const containerRef = useRef<HTMLDivElement | null>(null);
	const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

	useEffect(() => {
		const selectedIndex = options.findIndex((opt) => opt.id === selected.id);
		const selectedButton = buttonRefs.current[selectedIndex];

		if (selectedButton) {
			const { offsetWidth, offsetLeft } = selectedButton;
			setHighlightStyle({
				width: `${offsetWidth}px`,
				left: `${offsetLeft}px`,
			});
		}
	}, [selected]);

	const calculateDates = (option: Option) => {
		const currentDate = moment();
		let startDate = moment();
		let endDate = moment();

		if (option.unit === "yesterday") {
			startDate = currentDate.clone().subtract(1, "day").startOf("day");
			endDate = currentDate.clone().subtract(1, "day").endOf("day");
		} else {
			startDate = currentDate.clone().subtract(1, option.unit);
			endDate = currentDate;
		}

		return { startDate, endDate };
	};

	const handleSelect = (option: Option) => {
		setSelected(option);
		const { startDate, endDate } = calculateDates(option);
		if (onChange) {
			onChange({ startDate, endDate });
		}
	};

	return (
		<React.Fragment>
			<div className={styles.toggleContainer} ref={containerRef}>
				{options.map((option, index) => (
					<button
						key={option.id}
						ref={(el) => (buttonRefs.current[index] = el)}
						onClick={() => handleSelect(option)}
						className={`${styles.toggleButton} ${
							selected.id === option.id ? styles.active : ""
						}`}
					>
						{option.name}
					</button>
				))}
				<div className={styles.toggleHighlight} style={highlightStyle} />
			</div>

			<div className={styles.mobileToggle}>
				<Select
					placeholder={"Время"}
					popupMatchSelectWidth={false}
					options={options.map(
						({ id, name }: { id: number; name: string }) => ({
							value: id,
							id,
							label: name,
						})
					)}
					onChange={(value) => {
						const findOpinion = options.find((data) => data.id === value);
						findOpinion && handleSelect(findOpinion);
					}}
				/>
			</div>
		</React.Fragment>
	);
}
