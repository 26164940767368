import React from 'react'
import { Popover as AtndPopover } from 'antd'
import styles from './styles.module.scss'

import { TooltipPlacement } from 'antd/es/tooltip'

export type PopoverProps = React.ComponentProps<typeof AtndPopover> & {
  content?: React.ReactNode
  children?: React.ReactNode
  trigger?: 'hover' | 'click' | 'focus'
  placement?: TooltipPlacement
}

const Popover: React.FC<PopoverProps> = ({
  content,
  children,
  trigger = 'hover',
  placement = 'topLeft',
  ...restProps
}) => {
  return (
    <AtndPopover
      content={content}
      trigger={trigger}
      placement={placement}
      overlayClassName={styles.popover}
      arrow={false}
      {...restProps}
    >
      {children}
    </AtndPopover>
  )
}

export default Popover
