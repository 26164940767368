import * as yup from "yup";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	Button,
	Typography,
	Table,
	Modal,
	Form,
	Input,
	Popconfirm,
	Row,
} from "antd";

import Error from "components/Error";

import axios from "utils/axios";

import styles from "./styles.module.scss";

const { Title } = Typography;

const Roles = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [role, setRole] = useState([]);

	const getData = useCallback(async () => {
		try {
			setLoading(true);
			const res = await axios.get("/role");
			setData(res.data);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}, []);

	const handleDeleteRole = useCallback(
		async (role) => {
			try {
				await axios.delete(`/role/${role.id}`);
				getData();
			} catch (e) {
				console.log(e);
			} finally {
				setModalOpen(false);
			}
		},
		[getData]
	);

	const handleEditRole = (value) => {
		setRole(value);
		setModalOpen(true);
	};

	useEffect(() => {
		getData();
	}, [getData]);

	const columns = useMemo(
		() => [
			{
				id: 1,
				title: "№",
				dataIndex: "id",
				key: "id",
			},
			{
				title: "Название",
				dataIndex: "name",
				key: "name",
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const handleJustCloseModal = () => {
		setModalOpen(false);
		setRole([]);
	};

	const handleOnCloseModal = () => {
		handleJustCloseModal();
		getData();
	};

	return (
		<div>
			<div className={styles.topContainer}>
				<Title className={styles.title} level={2}>
					Список ролей
				</Title>
				<Button type="primary" onClick={handleOpenModal}>
					Добавить роль
				</Button>
			</div>

			<Table
				dataSource={data}
				columns={columns}
				loading={loading}
				pagination={false}
				rowClassName={styles.row}
				onRow={(record) => {
					return {
						onClick: () => {
							handleEditRole(record);
						},
					};
				}}
			/>
			<RoleModal
				role={role}
				justClose={handleJustCloseModal}
				onClose={handleOnCloseModal}
				isOpan={modalOpen}
				onRemove={handleDeleteRole}
			/>
		</div>
	);
};

export default Roles;

const RoleModal = (props) => {
	const { isOpan, onClose, onRemove, role, justClose } = props;
	const isEdit = role?.id;
	const [loading, setLoading] = useState(false);

	const handleSave = async (value) => {
		try {
			setLoading(true);
			isEdit
				? await axios.post(`/role/${role.id}`, value)
				: await axios.post("/role", value);
			onClose();
			resetForm();
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	const { values, errors, touched, setFieldValue, handleSubmit, resetForm } =
		useFormik({
			onSubmit: (values) => handleSave(values),
			initialValues: {
				name: role ? role.name : "",
			},
			validationSchema: yup.object().shape({
				name: yup.string().required("Это поле обязательно"),
			}),
			enableReinitialize: true,
		});

	const handleChange = (name) => (event) => {
		setFieldValue(name, event.target.value);
	};

	return (
		<Modal open={isOpan} footer={null} onCancel={justClose}>
			<div className={styles.formContainer}>
				<Form
					style={{ width: "100%" }}
					layout="vertical"
					onFinish={handleSubmit}
				>
					{isEdit ? (
						<Row>
							<Form.Item>
								<Popconfirm
									title={"Удалить роль"}
									okText={"Подтвердить"}
									cancelText={"Отмена"}
									onConfirm={() => onRemove(role)}
								>
									<Button loading={loading} type={"primary"} danger>
										Удалить
									</Button>
								</Popconfirm>
							</Form.Item>
						</Row>
					) : null}
					<Form.Item
						required
						label="Название"
						extra={touched.name && <Error message={errors.name} />}
					>
						<Input
							value={values.name}
							onChange={handleChange("name")}
							autoSize={{ minRows: 7 }}
							onPressEnter={handleSubmit}
						/>
					</Form.Item>

					<Button loading={loading} type="primary" htmlType="submit">
						{role?.name ? "Изменить" : "Создать"}
					</Button>
				</Form>
			</div>
		</Modal>
	);
};
