import React, { useState, useEffect, useMemo } from "react";
import { MenuProps } from "antd";
import styles from "./styles.module.scss";

import {
	GridContextProvider,
	GridDropZone,
	GridItem,
	swap,
	move,
} from "react-grid-dnd";
import { useDashboard } from "pages/Dashboard";
import Loading from "../Loading";
import WidgetTickets from "../WidgetComponents/WidgeTickets";
import WidgetIncidents from "../WidgetComponents/WidgeIncidents";
import WidgetAddWidget from "../WidgetComponents/WidgetAddWidget";
import WidgetKioskProblem from "../WidgetComponents/WidgetKioskProblem";

import { SettingsCardSvg } from "shared/icons";
import { Button } from "../Button";
import Popover from "../Popover";

type WidgetGridProps = {
	widgets: MenuProps["items"];
	loading: boolean;
	restart: boolean;
};

export type GridItemType = {
	position: number;
	name: string;
	div?: React.ReactNode;
};

export type ItemsType = {
	[key: string]: GridItemType[];
};

const componentRouter = (typeId: number) => {
	const restart = false;
	switch (typeId) {
		case 99:
			return <WidgetAddWidget />;
		case 3:
			return <WidgetKioskProblem />;
		case 2:
			return <WidgetIncidents />;
		case 1:
			return <WidgetTickets />;
		default:
			return <WidgetIncidents />;
	}
};

const GridItemContent = ({
	children,
	id,
	widgetTypeId,
}: {
	children?: React.ReactNode;
	id: number;
	widgetTypeId: number;
}) => {
	const { removeWidgetFromDashboard, isEditMode } = useDashboard();
	const [expanded, setExpanded] = useState(false);
	const [open, setOpen] = useState(false);
	const toggleSettings = () => {
		setExpanded(!expanded);
	};

	return (
		<div className={styles.gridItemContent}>
			{widgetTypeId === 99 || !isEditMode ? null : (
				<div
					className={[
						styles.widgetSettings,
						expanded ? styles.expanded : "",
					].join(" ")}
				>
					<Button onClick={toggleSettings} className={styles.buttonIcon}>
						<SettingsCardSvg />
					</Button>

					<div className={styles.menuCard}>
						<div className={styles.menuItem}>
							<Popover
								open={open}
								trigger={"click"}
								placement={"top"}
								content={
									<div className={styles.popover}>
										<div className={styles.header}>
											Удалить виджет из Дэшборда
										</div>
										<Button
											type="link"
											onClick={() => removeWidgetFromDashboard(id)}
										>
											Удалить
										</Button>
										<Button type="link" onClick={() => setOpen(false)}>
											Отмена
										</Button>
									</div>
								}
							>
								<Button type="link" danger onClick={() => setOpen(true)}>
									Удалить виджет
								</Button>
							</Popover>
						</div>
					</div>
				</div>
			)}

			{children}
		</div>
	);
};

const WidgetGrid: React.FC<WidgetGridProps> = ({ loading, widgets }) => {
	const { isEditMode, changeWidgetPositionInDashboard } = useDashboard();

	const [items, setItems] = React.useState<ItemsType>({ left: [], right: [] });

	const onChange = async (
		sourceId: string,
		sourceIndex: number,
		targetIndex: number,
		targetId?: string
	) => {
		if (!isEditMode) return false;

		if (!targetId || sourceId === targetId) {
			const result = swap(items[sourceId], sourceIndex, targetIndex);

			setItems({
				...items,
				[sourceId]: result,
			});

			const widgetId = items[sourceId][sourceIndex].id;

			await changeWidgetPositionInDashboard({
				widgetId,
				position: targetIndex + 1,
			});

			return;
		}

		const result = move(
			items[sourceId],
			items[targetId],
			sourceIndex,
			targetIndex
		);

		setItems({
			...items,
			[sourceId]: result[0],
			[targetId]: result[1],
		});

		// Получаем ID перемещаемого виджета
		const widgetId = items[sourceId][sourceIndex].id;

		await changeWidgetPositionInDashboard({
			widgetId,
			position: targetIndex + 1,
		});
	};

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [debouncedWidth, setDebouncedWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setDebouncedWidth(windowWidth);
		}, 300);

		return () => clearTimeout(timeout);
	}, [windowWidth]);
	const boxesPerRow = useMemo(() => {
		return Math.max(Math.floor(debouncedWidth / 400), 1);
	}, [debouncedWidth]);

	useEffect(() => {
		if (widgets?.length === 0) {
			setItems({
				left: [],
				right: [],
			});
		}
		const collectionWidgetRender = [
			...(widgets?.length ? widgets : []),
			...(isEditMode
				? [{ position: 99, widgetTypeId: 99, id: 99999, locked: true }]
				: []),
		].map(({ position, name, widgetTypeId, id }) => {
			return {
				position,
				name,
				id,
				div: componentRouter(widgetTypeId),
				locked: false,
				widgetTypeId,
			};
		});

		setItems({
			left: collectionWidgetRender,
			right: [],
		});
	}, [widgets, isEditMode]);

	return (
		<Loading loading={loading}>
			<GridContextProvider onChange={onChange}>
				<div className={styles.container}>
					<GridDropZone
						disableDrag={loading || !isEditMode}
						className={[
							styles.dropzone,
							isEditMode ? styles.activeDropZone : "",
						].join(" ")}
						id="left"
						rowHeight={360}
						boxesPerRow={boxesPerRow}
					>
						{items.left.map((item) => (
							<GridItem key={item.id} className={styles.item}>
								<div className={styles.gridItem}>
									<GridItemContent
										id={item.id}
										widgetTypeId={item.widgetTypeId}
									>
										{item.div}
									</GridItemContent>
								</div>
							</GridItem>
						))}
					</GridDropZone>
				</div>
			</GridContextProvider>
		</Loading>
	);
};

export default WidgetGrid;
