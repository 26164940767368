import React, { JSX } from "react";
import { Avatar, MenuProps } from "antd";
import styles from "./styles.module.scss";
import { Dropdown } from "../Dropdown";

type ProfileProps = {
	img?: React.ReactNode | JSX.Element;
	name: string;
	role: string;
	items: MenuProps["items"];
};

const Profile: React.FC<ProfileProps> = ({
	img,
	name,
	items,
	role,
}: ProfileProps) => {
	const renderProfileData = React.useCallback(() => {
		return (
			<div className={styles.container}>
				<Avatar icon={img} className={styles.avatar} />
				<div className={styles.info}>
					<div className={styles.name}>{name}</div>
					<div className={styles.role}>{role}</div>
				</div>
			</div>
		);
	}, [img, name, role]);

	return (
		<React.Fragment>
			<div id={"custom-message-container"}></div>
			<Dropdown
				// open
				items={items}
				header={<div className={styles.header}>{renderProfileData()}</div>}
			>
				<div className={styles.wrapper}>{renderProfileData()}</div>
			</Dropdown>
		</React.Fragment>
	);
};

export default Profile;
