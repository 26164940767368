import { Modal as AntModal, ModalProps as AntModalProps } from "antd";
import styles from "./styles.module.scss";
// Типы пропсов для кастомного модального окна
export interface ModalProps extends AntModalProps {}

// : React.FC<ModalProps> =
export function Modal({ children, className, ...restProps }: ModalProps) {
	return (
		<AntModal
			centered
			footer={null}
			{...restProps}
			className={[styles.modal, className].join(" ")}
		>
			{children}
		</AntModal>
	);
}
