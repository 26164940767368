import { useCallback, useState, useEffect } from "react";
import { Select } from "shared/components";

import { useProfile } from "hooks/useContext";

export type Role = { id: number; name: string };

const SelectRole = ({ onChange }: { onChange?: (roleId: number) => void }) => {
	const [roles, setRoles] = useState<
		{ value: number; id: number; label: string }[]
	>([]);

	const [value, setValue] = useState<number | undefined>(undefined);
	const { user, loading } = useProfile();

	const setData = useCallback(
		async (data: Role[]) => {
			if (!data.length) return false;
			onChange && onChange(data[0].id);
			setValue(data[0].id);
			setRoles(
				data.map(({ id, name }: { id: number; name: string }) => ({
					value: id,
					id,
					label: name,
				}))
			);
		},
		[onChange]
	);

	useEffect(() => {
		if (user) {
			const { roles }: { roles: Role[] } = user;
			setData(roles);
		}
	}, [user]);

	return (
		<Select
			popupMatchSelectWidth={false}
			options={roles}
			value={value}
			loading={loading}
			style={{ width: "154px", marginRight: "10px" }}
			onChange={(value) => {
				onChange && onChange(value);
				setValue(value);
			}}
		/>
	);
};

export default SelectRole;
