import React, { useEffect } from "react";
import { Menu as AntMenu, Tour } from "antd";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

import {
	PageSvg,
	SearchSvg,
	MerchantSvg,
	UserSvg,
	SoftwareSvg,
	TagSvg,
	TypeSvg,
	ScheduleSvg,
	NotificationSvg,
	ReferenceSvg,
} from "../../icons";

// import { UserOutlined } from "@ant-design/icons";

import { useProfile } from "hooks/useContext";
import type { MenuProps, TourProps } from "antd";

import { CharSvg } from "../../icons/mascot";
import Profile from "../Profile";

import { Link } from "react-router-dom";

const { SubMenu } = AntMenu;

const menuData = [
	{
		key: "1",
		label: "Запросы",
		path: "/",
		icon: (
			<div>
				<PageSvg />
			</div>
		),
		children: [
			{ key: "1-1", label: "Обращения", path: "/admin/tickets" },
			{ key: "1-2", label: "Инциденты", path: "/admin/incidents" },
		],
	},
	{
		key: "2",
		label: "Проблемы",
		path: "/problems/",
		icon: (
			<div>
				<SearchSvg />
			</div>
		),
		children: [
			{ key: "2-1", label: "Системные проблемы", path: "/admin/problems" },
			{
				key: "2-2",
				label: "Проблемные места",
				path: "/admin/problemplace",
			},
		],
	},
	{
		key: "3",
		label: "Мерчанты",
		path: "/merchants",
		icon: (
			<div>
				<MerchantSvg />
			</div>
		),
		children: [
			{ key: "3-1", label: "Список мерчантов", path: "/admin/merchants" },
			{
				key: "3-2",
				label: "Сообщение мерчанту",
				path: "/admin/merchant-bot-message",
			},
		],
	},
	{
		key: "4",
		label: "Пользователи",
		path: "/users/",
		icon: (
			<div>
				<UserSvg />
			</div>
		),
		children: [
			{ key: "4-1", label: "Учетные записи", path: "/admin/users" },
			{ key: "4-2", label: "Роли", path: "/admin/roles" },
		],
	},
	{
		key: "5",
		label: "ПО",
		path: "/admin/softwares",
		icon: (
			<div>
				<SoftwareSvg />
			</div>
		),
	},
	{
		key: "6",
		label: "Теги",
		path: "/admin/tags",
		icon: (
			<div>
				<TagSvg />
			</div>
		),
	},
	{
		key: "7",
		label: "Отчеты",
		path: "/admin/reports",
		icon: (
			<div>
				<TagSvg />
			</div>
		),
	},
	{
		key: "8",
		label: "Типы",
		path: "/admin/types",
		icon: (
			<div>
				<TypeSvg />
			</div>
		),
	},
	{
		key: "9",
		label: "Сообщения",
		path: "/admin/announcement",
		icon: (
			<div>
				<NotificationSvg />
			</div>
		),
	},
	{
		key: "10",
		label: "График",
		path: "/admin/schedule",
		icon: (
			<div>
				<ScheduleSvg />
			</div>
		),
	},
	{
		key: "11",
		label: "База знаний",
		path: "/knowledge-base",
		icon: (
			<div>
				<ReferenceSvg />
			</div>
		),
	},
	{
		key: "12",
		label: "Разработка",
		path: "/development",
		children: [
			{
				key: "12-1",
				label: "Дашборд",
				path: "/development/dashboard",
			},
		],
	},
];

const Exit = () => {
	const { logout } = useProfile();
	const handleLogout = () => {
		logout();
	};

	return (
		<span className={styles.buttonExit} onClick={handleLogout}>
			Выход
		</span>
	);
};

const renderMenu = (menuItems: typeof menuData) => {
	return menuItems.map((item, index) => {
		if (item.children) {
			return (
				<>
					<SubMenu
						key={item.path}
						icon={item.icon}
						title={item.label}
						className={styles.subMenuItem}
					>
						<li className={styles.submenuTitle}>{item.label}</li>
						{renderMenu(item.children as typeof menuData)}
					</SubMenu>
				</>
			);
		}
		return (
			<>
				{index > 0 && <span className={styles.dividerItem} />}
				<AntMenu.Item
					key={item.path}
					icon={item.icon}
					className={styles.menuItem}
				>
					<Link to={item.path}>{item.label}</Link>
				</AntMenu.Item>
			</>
		);
	});
};

type UserType = {
	name: string;
	roles: { name: string }[];
} | null;

export const Menu = () => {
	const history = useHistory();
	const User: { user: UserType } = useProfile();

	const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

	const handleSelect = ({ selectedKeys }: { selectedKeys: string[] }) => {
		setSelectedKeys(selectedKeys);
	};

	const items: MenuProps["items"] = [
		{
			key: "1",
			label: (
				<Link
					to={"/admin/profile"}
					onClick={() => handleSelect({ selectedKeys: ["/admin/profile"] })}
				>
					Профиль
				</Link>
			),
		},
		{
			key: "2",
			label: <span onClick={() => setOpen(true)}>Обучение</span>,
		},
		{
			key: "3",
			label: <Exit />,
		},
	];

	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);
	const ref3 = React.useRef(null);

	const [open, setOpen] = React.useState<boolean>(false);

	const steps: TourProps["steps"] = [
		{
			title: "Новое Меню Навигации",
			description:
				"Добро пожаловать в руководство по использованию нового меню навигации! Здесь вы узнаете, как эффективно работать с обновленным интерфейсом и настроить его под свои потребности.",
			nextButtonProps: { className: styles.nextButton },
			prevButtonProps: { className: styles.nextButton },
			target: () => ref1.current,
		},

		{
			title: "Основные функции меню",
			description: (
				<div>
					<span>
						Меню навигации предлагает удобный способ перемещения между основными
						разделами ServiceDesk:
					</span>

					<ul>
						<li>
							Индикатор активного элемента: Текущий раздел выделяется
							автоматически для упрощения ориентации.
						</li>
						<li style={{ opacity: 0.5 }}>
							Меню может быть настроено в виде горизонтального на верхней панели
							или нижней (скоро)
						</li>
					</ul>
				</div>
			),
			prevButtonProps: { className: styles.nextButton },
			nextButtonProps: { className: styles.nextButton },
			target: () => ref2.current,
		},
		{
			title: "Профиль сотрудника",
			description: (
				<div>
					В профиле сотрудника вы можете просмотреть данные пользователя или
					выйти из системы, если это необходимо.
				</div>
			),
			prevButtonProps: { className: styles.nextButton },
			nextButtonProps: { className: styles.nextButton },
			target: () => ref3.current,
		},
	];

	useEffect(() => {
		handleSelect({ selectedKeys: [history.location.pathname] });
	}, []);

	return (
		<React.Fragment>
			<div className={styles.panel} ref={ref1}>
				<div className={styles.logo}>
					<span className={styles.text}>ServiceDesk</span>
				</div>
				<span className={styles.divider} />
				<div className={styles.menuContainer} ref={ref2}>
					<AntMenu
						className={styles.menu}
						theme="dark"
						mode="horizontal"
						onSelect={handleSelect}
						selectedKeys={selectedKeys}
					>
						{renderMenu(menuData)}
					</AntMenu>
				</div>
				<span className={styles.divider} style={{ marginRight: "20px" }} />
				<span ref={ref3}>
					<Profile
						img={<CharSvg className={styles.char} />}
						items={items}
						name={User.user?.name || ""}
						role={User.user?.roles[0].name || ""}
					/>
				</span>
			</div>
			<Tour
				open={open}
				onClose={() => setOpen(false)}
				steps={steps}
				arrow={false}
				className={styles.tour}
			/>
		</React.Fragment>
	);
};
