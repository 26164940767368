import { notification } from "antd";
import styles from "./styles.module.scss";
import { CharSvg } from "shared/icons/mascot";
export type MessageType = "success" | "info" | "error";

notification.config({
	placement: "bottom",
	bottom: 90,
});

const Notification = (
	type: MessageType,
	content: string,
	duration?: number
) => {
	notification.open({
		className: styles.container,
		type,
		message: "",
		description: content,
		duration: duration || 2,
		icon: (
			<CharSvg
				style={{
					width: "26px",
					height: "26px",
				}}
			/>
		),
		// closeIcon: null,
	});
};

export default Notification;
