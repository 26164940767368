import { Select } from "antd";
// import 'antd/dist/antd.css'
import React from "react";
const { Option } = Select;

const MultiSelect = ({
	mode,
	value,
	defaultValue,
	options,
	onChange,
	className,
	placeholder,
	...rest
}) => {
	//mode can be a) multiple; b) tags

	return (
		<Select
			placeholder={placeholder}
			onChange={onChange}
			mode={mode}
			defaultValue={defaultValue}
			value={value}
			showSearch
			showArrow
			className={className}
			filterOption={(input, option) =>
				option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
			}
			{...rest}
		>
			{options.map((o) => (
				<Option title={o.tooltip} key={o.id} value={o.id}>
					{o.label}
				</Option>
			))}
		</Select>
	);
};

export default MultiSelect;
