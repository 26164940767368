import { Switch as AtndSwitch, SwitchProps as AntSwitchProps } from "antd";
import styles from "./styles.module.scss";

export interface SwitchProps extends AntSwitchProps {}

export function Switch({ className, ...restProps }: SwitchProps) {
	return (
		<AtndSwitch
			{...restProps}
			className={[styles.switch, className].join(" ")}
		/>
	);
}
