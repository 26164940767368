import { DatePicker as AntdDatePicker } from "antd";
import styles from "./styles.module.scss";
import type { Dayjs } from "dayjs";
import dayjs, { ConfigType } from "dayjs";
const { RangePicker } = AntdDatePicker;
import { CalendarSvg } from "../../icons";
import { dateToApiDateFormat } from "utils/helpers";

type PropsDateFilter = {
	onChange: (params: { fieldName: string; values: [string, string] }) => void;
	name: string;
	values?: ConfigType[];
};

export type OnChangeParams = { fieldName: string; values: Dayjs };

export function DatePicker({
	onChange,
	name,
	values = [dayjs(), dayjs()],
}: PropsDateFilter) {
	const handlerChange = (date: Dayjs | (Dayjs | null)[] | null) => {
		if (!date) return;

		if (Array.isArray(date)) {
			const start = date[0]
				? dateToApiDateFormat(date[0])
				: dateToApiDateFormat(dayjs());
			const end = date[1]
				? dateToApiDateFormat(date[1])
				: dateToApiDateFormat(dayjs());

			onChange &&
				onChange({
					fieldName: name,
					values: [start, end],
				});
		}
	};

	return (
		<RangePicker
			format="DD MMM YYYY"
			defaultValue={[dayjs(values[0]), dayjs(values[1])]}
			name={name}
			value={[dayjs(values[0]), dayjs(values[1])]}
			className={styles.datePicker}
			onChange={handlerChange}
			dropdownClassName={styles.dropdown}
			separator="-"
			suffixIcon={<CalendarSvg />}
		/>
	);
}
