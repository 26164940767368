import React from "react";
import styles from "./styles.module.scss";

type BarData = {
	label: string;
	value: number;
	color: string;
};

type DayData = {
	label: string;
	bars: BarData[];
};

type CustomBarChartProps = {
	data: DayData[];
	onClick: (data: BarData) => void;
};

const CustomBarChart: React.FC<CustomBarChartProps> = ({ data, onClick }) => {
	const calculatedMaxValue = Math.max(
		...data.flatMap((day) => day.bars.map((bar) => bar.value))
	);

	return (
		<div
			className={[
				styles.chartContainer,
				data.length > 6 ? styles.floatCenter : styles.floatLeft,
			].join(" ")}
		>
			{data.map((day, dayIndex) => (
				<div key={dayIndex} className={styles.column}>
					<div className={styles.barStack}>
						{day.bars.map((bar, barIndex) => (
							<div
								key={barIndex}
								className={styles.bar}
								style={{
									height: `${(bar.value / calculatedMaxValue) * 100}%`,
									backgroundColor: bar.color,
								}}
								title={`${bar.label}: ${bar.value}`}
								onClick={() => onClick && onClick(bar)}
							>
								<span className={styles.number}>{bar.value}</span>
							</div>
						))}
					</div>
					<div className={styles.dayLabel}>{day.label}</div>
				</div>
			))}
		</div>
	);
};

export default CustomBarChart;
