import { useEffect, useState, useCallback } from "react";

import axios from "utils/axios";

type Widget = {
	id: number;
	typeId: number;
	position: number;
};

export type Scheme = {
	id: number;
	roleId: number;
	widgetId: number;
	priority: number;
	name: string;
	dashboardId: number;
};

export type SchemeOne = {
	id: number;
	priority: number | null;
	dashboardId: number;
	name: string;
};

export const useWidgetGrid = () => {
	const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({
		init: true,
	});

	const setLoading = useCallback((key: string, value: boolean) => {
		setLoadingStates((prevState) => ({
			...prevState,
			[key]: value,
		}));
	}, []);

	const [schemeWidgets, setSchemeWidgets] = useState<number | undefined>();
	const [editMode, setEditMode] = useState<boolean>(false);

	const [widgets, setWidgets] = useState<Widget[] | null>(null);

	const [schemes, setSchemes] = useState<Scheme[] | []>([]);

	const [error, setError] = useState<string | null>(null);

	const fetchSchemesForRole = useCallback(
		async (roleId: string) => {
			setLoading("schemes", true);
			const response = await axios
				.get(`/widget/${roleId}/dashboards`)
				.finally(() => setLoading("schemes", false));

			const data: Scheme[] = response?.data || [];

			setSchemes(data);

			if (response.data.length) {
				const res = response?.data?.[0] || undefined;
				setSchemeWidgets(res.id);
			}

			return response;
		},
		[schemeWidgets]
	);

	const getDashbaordId = useCallback(
		(id: number | undefined) => {
			const res = schemes.find((scheme) => id === scheme.id) || undefined;
			return res?.dashboardId || undefined;
		},
		[schemes]
	);

	const fetchWidgets = useCallback(async () => {
		const id = getDashbaordId(schemeWidgets);
		const response = await axios
			.get(`/widget/${id}/dashboard`)
			.finally(() => setLoading("schemes", false));

		const data: Widget[] = response?.data || [];

		setWidgets(data);

		return data;
	}, [schemeWidgets, schemes]);

	const setWidgetDashbaord = useCallback(
		async ({
			widgetId,
			dashboardId,
		}: {
			widgetId: number;
			dashboardId: number;
		}) => {
			const id = getDashbaordId(dashboardId);

			const response = await axios.put(`/widget/${id}/dashboard`, {
				widgetId,
			});
			// .finally(() => setLoading("set", false));

			const data: Widget[] = response?.data || [];

			setWidgets(data);

			return data;
		},
		[schemes]
	);

	const changeWidgetPositionInDashboard = useCallback(
		async ({ widgetId, position }: { widgetId: number; position: number }) => {
			const id = getDashbaordId(schemeWidgets);

			const response = await axios.put(`/widget/${id}/sort`, {
				widgetId,
				position,
				dashboardId: id,
			});

			return response.data;
		},

		[schemeWidgets]
	);

	const removeWidgetFromDashboard = useCallback(
		async (id: number) => {
			const dashboardId = getDashbaordId(schemeWidgets);
			const response = await axios.delete(
				`/widget/${id}/dashboard/${dashboardId}`
			);
			// .finally(() => setLoading("schemes", false));

			// const data: Widget[] = response?.data || [];
			// setWidgets(data);
			const data: Widget[] = response?.data || [];

			setWidgets(data);
		},

		[schemeWidgets]
	);

	const setPriorityDashboard = useCallback(
		async (id: number) => {
			const selectedScheme =
				schemes.find((scheme) => id === scheme.id) || undefined;

			const response = await axios.put(`/widget/dashboard/priority`, {
				dashboardId: selectedScheme?.dashboardId,
				schemeId: id,
				roleId: selectedScheme?.roleId,
			});

			const data: Scheme[] = response?.data || [];

			setSchemes(data);

			if (response.data.length) {
				const res = response?.data?.[0] || undefined;

				setSchemeWidgets(res.id);
			}
		},

		[schemeWidgets, schemes]
	);

	const addWidget = useCallback(
		(id: number) => {
			if (id && schemeWidgets) {
				setWidgetDashbaord({
					widgetId: id,
					dashboardId: schemeWidgets,
				});
			}
		},
		[schemeWidgets]
	);

	useEffect(() => {
		if (schemeWidgets) {
			fetchWidgets();
		}
	}, [schemeWidgets]);

	return {
		isLoadingWidgets: loadingStates?.["schemes"] || false,
		schemes,
		fetchSchemesForRole,
		schemeWidgets,
		setSchemeWidgets,
		addWidget,
		widgets,
		error,
		changeWidgetPositionInDashboard,
		removeWidgetFromDashboard,
		setPriorityDashboard,
	};
};
