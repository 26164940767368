import { Select as AntdSelect } from "antd";
import { SelectProps as AntdSelectProps, SelectValue } from "antd/lib/select";

import styles from "./styles.module.scss";

export interface SelectProps<VT> extends AntdSelectProps<VT> {
	name?: string;
}

export function Select<ValueType extends SelectValue = SelectValue>(
	props: SelectProps<ValueType>
) {
	const selectProps: typeof props = {
		...props,
		options: null,
	};

	return (
		<AntdSelect<ValueType>
			dropdownStyle={{
				boxShadow: "none",
				borderRadius: 0,
				padding: 0,
				background: "none",
			}}
			{...selectProps}
			popupClassName={styles.dropdown}
			className={[styles.select, props.className || ""].join(" ")}
		>
			{props.options.map((o) => (
				<AntdSelect.Option title={o.tooltip} key={o.id} value={o.id}>
					{o.label}
				</AntdSelect.Option>
			))}
		</AntdSelect>
	);
}

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
