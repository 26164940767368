import { useState } from "react";
import styles from "./styles.module.scss";

interface DataPoint {
	name: string;
	total: number;
	id: number;
}

interface BarChartProps {
	data: DataPoint[];
	onClick?: (data: DataPoint) => void;
}

const getTextColor = (bgColor: string, strongColor: boolean) => {
	const hex = bgColor.replace("#", "");
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	const brightness = (r * 299 + g * 587 + b * 114) / 1000;
	return brightness < 128
		? `rgba(255,255,255, ${strongColor ? 1 : 0.4})`
		: `rgba(0,0,0,  ${strongColor ? 1 : 0.4})`;
};

const BarChart = ({ data, onClick }: BarChartProps) => {
	const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

	const getColor = (index: number) => {
		const colors = ["#7E57C2", "#BDBDBD", "#FF9800", "#212121", "#E57373"];
		return colors[index % colors.length];
	};

	const maxTotal = Math.max(...data.map((d) => d.total));
	const minTotal = Math.min(...data.map((d) => d.total));

	const calculateHeight = (total: number) => {
		return (total / maxTotal) * 100;
	};

	return (
		<div
			style={{
				display: "flex",
				alignItems: "flex-end",
				position: "relative",
				height: "265px",
				width: "100%",
			}}
		>
			{data.map((item, index) => {
				const barHeight = calculateHeight(item.total);
				const baseZIndex =
					item.total === minTotal ? data.length + 1 : data.length - index;
				const isHovered = hoveredIndex === index;

				return (
					<div
						key={item.name}
						style={{
							flex: 1,
							height:
								item.total === 0
									? isHovered
										? `${barHeight + 20}%`
										: calculateHeight(minTotal + 1)
									: `${isHovered ? barHeight + 20 : barHeight}%`,
							backgroundColor: getColor(index),
							marginRight: index !== data.length - 1 ? "-50px" : "0",
							position: "relative",
							zIndex: isHovered ? 100 : baseZIndex,
							display: "flex",
						}}
						className={isHovered ? styles.animate : ""}
						onMouseEnter={() => setHoveredIndex(index)}
						onMouseLeave={() => setHoveredIndex(null)}
						onClick={() => onClick && onClick(item)}
					>
						<span className={styles.bar}>
							<span
								className={styles.number}
								style={{ color: getTextColor(getColor(index), true) }}
							>
								{item.total}
							</span>
							<span style={{ color: getTextColor(getColor(index), false) }}>
								{item.name}
							</span>
						</span>
					</div>
				);
			})}
		</div>
	);
};

export default BarChart;
