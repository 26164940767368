import React, { SVGProps } from "react";
export const CharSvg: React.FC<SVGProps<SVGSVGElement>> = (
	props: SVGProps<SVGSVGElement>
) => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.37481 4.52601C2.37481 2.42008 4.082 0.712891 6.18793 0.712891L6.18793 1.83651C6.8785 1.14249 7.83457 0.712891 8.89098 0.712891V1.84108C9.5819 1.14437 10.5398 0.712891 11.5986 0.712891V4.53159C11.599 4.53226 11.5995 4.53293 11.6 4.5336C12.8716 4.68443 13.8578 5.76633 13.8578 7.07856C13.8578 8.12907 13.2258 9.03197 12.3212 9.42773C11.5967 11.6761 9.48722 13.3028 6.99785 13.3028C4.5076 13.3028 2.39755 11.6749 1.67371 9.42533C0.772069 9.02833 0.142578 8.12696 0.142578 7.07856C0.142578 5.77515 1.11555 4.69899 2.37481 4.53676V4.52601Z"
			fill="white"
		/>
		<path
			d="M4.23661 7.14512C4.23661 7.95397 3.58092 8.60966 2.77208 8.60966C1.96324 8.60966 1.30754 7.95397 1.30754 7.14512C1.30754 6.33628 1.96324 5.68059 2.77208 5.68059C3.58092 5.68059 4.23661 6.33628 4.23661 7.14512Z"
			stroke="black"
			strokeWidth="0.732268"
		/>
		<path
			d="M12.6922 7.14512C12.6922 7.95397 12.0365 8.60966 11.2276 8.60966C10.4188 8.60966 9.76311 7.95397 9.76311 7.14512C9.76311 6.33628 10.4188 5.68059 11.2276 5.68059C12.0365 5.68059 12.6922 6.33628 12.6922 7.14512Z"
			stroke="black"
			strokeWidth="0.732268"
		/>
		<rect
			x="2.33887"
			y="6.71289"
			width="0.865408"
			height="0.865408"
			fill="black"
		/>
		<rect
			x="10.7944"
			y="6.71289"
			width="0.865408"
			height="0.865408"
			fill="black"
		/>
		<path
			d="M9.1553 8.64258C8.54699 9.10325 7.78893 9.37655 6.96703 9.37655C6.15517 9.37655 5.4056 9.10988 4.80111 8.65937L4.36572 9.23113C5.11798 9.80397 6.03829 10.1125 6.98382 10.1088C7.92934 10.1051 8.84721 9.78939 9.59497 9.21069L9.1553 8.64258Z"
			fill="black"
		/>
	</svg>
);
